import { ReactNode, useEffect, EffectCallback, DependencyList } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";

import PopupModal from "src/components/Modal";

type Props = {
  children: ReactNode;
  title: string;
  showModal?: boolean;
  handleModal?: () => void;
  modalComponent?: ReactNode;
  effectCallback?: EffectCallback;
  dependencyList?: DependencyList;
};

const Layout = ({
  children,
  title = "Sentris",
  showModal = false,
  handleModal = () => {},
  modalComponent = <></>,
  effectCallback = () => {},
  dependencyList = [],
}: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, dependencyList);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        {children}
      </Container>
      <PopupModal
        show={showModal}
        handleModal={(_, reason) => {
          if (reason === "backdropClick") return;
          handleModal();
        }}
        component={modalComponent}
      />
    </>
  );
};

export default Layout;
