import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface UserState {
  listUsers: any[];
  listRole: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: UserState = {
  listUsers: [],
  listRole: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  isShowPassword: false,
  isShowConfPassword: false,
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updateEmployee: (state, { payload }) => {
      const [findData] = state.listUsers.filter((data: any) => data.id === payload);
      if (findData !== undefined) {
        state.showModal = !state.showModal;
        state.id = findData.id;
        state.editMode = true;
        state.editValue = findData;
      }
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
    handleShowPass: (state, { payload }) => {
      if (payload === "password") {
        state.isShowPassword = !state.isShowPassword;
      }
      if (payload === "confPassword") {
        state.isShowConfPassword = !state.isShowConfPassword;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createEmployee.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createEmployee.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        const { data, roles, count } = payload;
        return {
          ...state,
          isLoading: false,
          listUsers: data,
          listRole: roles,
          count,
        };
      })
      .addCase(getEmployees.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editEmployee.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editEmployee.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listUsers.findIndex((prevData: any) => prevData.id === data.id);
        state.isDisabled = false;
        state.listUsers[index] = data;
      })
      .addCase(editEmployee.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { showPopupModal, handleShowPass, updateEmployee, handleChange } = slice.actions;
export default slice.reducer;

export const getEmployees = createAsyncThunk(
  "getEmployees",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/employees?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createEmployee = createAsyncThunk("createEmployee", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/employees`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editEmployee = createAsyncThunk("editEmployee", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/employees`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteEmployee = createAsyncThunk("deleteEmployee", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/employees/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
