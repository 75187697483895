import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listAnnounce: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: State = {
  listAnnounce: [],
  editMode: false,
  isDisabled: false,
  isLoading: true,
  showModal: false,
  id: "",
  editValue: {},
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedAnnounce: (state, { payload }) => {
      const [findAnnounce] = state.listAnnounce.filter((data: any) => data.id === payload);
      if (findAnnounce !== undefined) {
        state.showModal = !state.showModal;
        state.id = findAnnounce.id;
        state.editMode = true;
        state.editValue = findAnnounce;
      }
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAnnouncement.fulfilled, (state, { payload }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listAnnounce: data,
          count,
        };
      })
      .addCase(getAllAnnouncement.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(createAnnouncement.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createAnnouncement.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createAnnouncement.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editAnnoucement.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editAnnoucement.fulfilled, (state, { meta: { arg } }) => {
        const { title, id, content } = arg;
        const index = state.listAnnounce.findIndex((data: any) => data.id === id);
        const newValue: any = { id, title, content };
        state.isDisabled = false;
        state.listAnnounce[index] = newValue;
      })
      .addCase(editAnnoucement.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { showPopupModal, handleChange, updatedAnnounce } = slice.actions;
export default slice.reducer;

export const getAllAnnouncement = createAsyncThunk(
  "getAllAnnouncement",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/announcement?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createAnnouncement = createAsyncThunk("createAnnouncement", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/announcement`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editAnnoucement = createAsyncThunk("editAnnoucement", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/announcement`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteAnnouncement = createAsyncThunk("deleteAnnouncement", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/announcement/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
