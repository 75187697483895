import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listRole: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: state = {
  listRole: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const slice = createSlice({
  name: "team roles",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedTeamRole: (state, { payload }) => {
      const [findRole] = state.listRole.filter((data: any) => data.id === payload);
      if (findRole !== undefined) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeamRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          isLoading: false,
          listRole: data,
        };
      })
      .addCase(getAllTeamRole.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createTeamRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createTeamRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listRole = [...state.listRole, data];
      })
      .addCase(createTeamRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editeTeamRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editeTeamRole.fulfilled, (state, { meta: { arg } }) => {
        const { name, id } = arg;
        const index = state.listRole.findIndex((data: any) => data.id === id);
        const newValue = { id, name };
        state.isDisabled = false;
        state.listRole[index] = newValue;
      })
      .addCase(editeTeamRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteTeamRole.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listRole.filter((data: any) => data.id !== arg);
        state.listRole = newData;
      });
  },
});
export const { showPopupModal, updatedTeamRole } = slice.actions;
export default slice.reducer;

export const getAllTeamRole = createAsyncThunk("getAllTeamRole", async () => {
  try {
    const response = await axiosInstance.get(`/roles`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createTeamRole = createAsyncThunk("createTeamRole", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/roles`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editeTeamRole = createAsyncThunk("editeTeamRole", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/roles`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteTeamRole = createAsyncThunk("deleteTeamRole", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/roles/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
