import { ReactNode } from "react";
import { Box, Dialog } from "@mui/material";

type Props = {
  show: boolean;
  handleModal: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  component: ReactNode;
  style?: {};
};

export default function PopupModal({ show, handleModal, component, style }: Props) {
  return (
    <Dialog
      disableEscapeKeyDown
      open={show}
      onClose={handleModal}
      scroll="body"
      maxWidth="xl"
      keepMounted
    >
      <Box
        sx={{
          p: 2,
        }}
        style={style}
      >
        {component}
      </Box>
    </Dialog>
  );
}
